<script setup>
import { ref, watchEffect, onMounted } from 'vue';
import * as Sentry from '@sentry/vue';

const emit = defineEmits(['hideMessage']);
const props = defineProps({
  type_message: {
    type: String,
    required: true
  },
  content_messages: {
    type: Array,
    required: true
  },
  title_message: {
    type: String,
    required: false
  }
});

const isShow = ref(false);
const isError = ref(false);
const isWarn = ref(false);
const isInfo = ref(false);
const isSuccess = ref(false);


const processType = () => {
  try {
    // isShow.value = props.content_messages.length && props.content_messages[0]?.trim().length
    isShow.value = props.content_messages.length && typeof props.content_messages[0] === 'string' && props.content_messages[0].trim().length;
    isError.value = props.type_message === 'error';
    isInfo.value = props.type_message === 'fact';
    isWarn.value = ['warning', 'alert'].includes(props.type_message);
    isSuccess.value = ['success', 'notice'].includes(props.type_message);
  } catch (err) {
    Sentry.captureException(err, { extra:  props.content_messages  });
  }
}

onMounted(() => {
  processType();
});

watchEffect(() =>{
  processType();
})

const hideMessage = () => {
  isShow.value = false
  emit('hideMessage');
}
</script>

<template>
  <div class='w-full my-4 ml-auto mr-auto' v-if='isShow'>
    <div id="box_message" class='text-sm leading-5'
         :class="{ 'color-danger' : isError, 'color-warn' : isWarn, 'color-info' : isInfo, 'color-success' : isSuccess }"
         role="alert">
      <div id='message' class='w-4/5 ml-auto mr-auto py-4 pt-5'>
        <div class="flex">
          <div class="w-1/12">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 -left-8 svg-2" viewBox="0 0 20 20"
                 fill="currentColor" :class="{ 'rotate-180' : (isInfo || isSuccess)}">
              <path fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"/>
            </svg>
          </div>
          <div class="w-10/12">
            <h1 class='font-semibold capitalize message-type-message'>
              {{ type_message }}
            </h1>
            <h2 class="my-2 message-title-message" v-if="title_message">
              {{ title_message }}
            </h2>
            <ul v-if="content_messages.length > 1" class="list-disc pl-8 message-content-message-list">
              <li class="message-content-message-item" v-for="message in content_messages" :key="message" v-html="message">
              </li>
            </ul>
            <h2 class="my-2 message-content-messages-0" v-if="content_messages.length == 1" v-html="content_messages[0]">
            </h2>
          </div>
          <div class="w-1/12">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 font-semibold svg-1 cursor-pointer message-click-btn"
                 viewBox="0 0 20 20" fill="currentColor" @click="hideMessage">
              <path fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#box_message {
  /* UI/Action/Focus/Focus */
  box-sizing: border-box;
  /* Shadow/ExSmall */
  border: 1px solid;
  border-top: 0.55rem solid;
  box-shadow: 0px 4px 8px rgba(10, 92, 94, 0.08);
  border-radius: 4px;
}

#message {

  font-family: Open Sans;
  font-style: normal;
  color: #101C24;
}

.color-success {
  .svg-1 {
    color: #608023;
  }

  .svg-2 {
    color: #A2CF4E;
  }

  background: #FBFAE8;
  border-color: #608023 !important;
}

.color-info {
  .svg-1 {
    color: #101C24;
  }

  .svg-2 {
    color: #4A5D69;
  }

  background: #EDF9FD;
  border-color: #004B97 !important;
}

.color-warn {
  .svg-1 {
    color: #A2682A;
  }

  .svg-2 {
    color: #ECC062;
  }

  background: #FEF8EB;
  border-color: #A2682A !important;
}

.color-danger {
  .svg-1 {
    color: #E04239;
  }

  .svg-2 {
    color: #E04239;
  }

  background: #FDEDEF;
  border-color: #B0231C !important;
}
</style>
