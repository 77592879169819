import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

import "../stylesheets/application.css"
import TurbolinksAdapter from "vue-turbolinks"
import "../stylesheets/application.css"

import LogRocket from "logrocket"

import { createApp } from "vue/dist/vue.esm-bundler.js"

import mitt from "mitt"
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"
import { setupPinia } from "../src/pinia/piniaUtil"
import router from "../src/routes/router"
import App from "../src/components/App.vue"
/* below Elements are retained for static pages */
import Navigation from "../src/components/design-system/organisms/Navigation.vue"
import Message from "../src/components/design-system/organisms/recommendations/message.vue"

/**
 * For vue3 components use the useEventBus composable instead of the global eventBus window object.
 * @deprecated
 */
if (typeof(window.eventBus) === "undefined") {
  window.eventBus = mitt()
}

require.context("../images", true)

Rails.start()
Turbolinks.start()
ActiveStorage.start()

try{
  LogRocket.init("s5rjbz/pilot-recommendation-engine");
} catch (error) {
  console.log(error)
}

const initializeSentry = function(app) {
  Sentry.init({
    app,
    dsn: "https://986e79b112dc4ef7a7992dae8acf6c03@o1081935.ingest.sentry.io/6089916",
    integrations: [
      new BrowserTracing({
        tracingOrigins: ["overalls.ai", /^\//],
      }),
    ],
    denyUrls: ["localhost", "127.0.0.1"],
    enabled: true,
    tracesSampleRate: 1.0,
  })
}

const setup = () => {

  /*this is just for static pages ATM
   */
  document.addEventListener("turbolinks:load", () => {
    if (document.getElementById("navigation")) {
      const app = createApp({
        data() { return {} },
        mixins: [TurbolinksAdapter],
        components: { Navigation }
      }).use(router)
      initializeSentry(app);
      setupPinia(app);
      app.mount("#navigation")
    }
  })

  document.addEventListener("turbolinks:load", () => {
    if (document.getElementById("message")) {
      const app = createApp({
        data() { return {} },
        mixins: [TurbolinksAdapter],
        components: { Message }
      }).use(router)
      initializeSentry(app)
      setupPinia(app);
      app.mount("#message")
    }
  })

}

(function(){
  if (getCookie("user_id")) {
    LogRocket.identify(getCookie("user_id"), {
      email: decodeURIComponent(getCookie("user_email"))
    })
  }
})()

function getCookie(cname) {
  const name = cname + "="
  const ca = document.cookie.split(";")
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
};

setup()
